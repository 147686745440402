import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { createSelector } from "reselect";
import { ThemeContext } from "contextProviders/ThemeProvider";

import * as actions from "./actions";

import Theming from "./component";

const themeSelect = createSelector(
  (state) => state.app,
  (app) => app.color
);

export const currentUserStateSelect = createSelector(
  (state) => state.app,
  (app) => app.currentUser
);

const ThemingContainer = (props) => {
  const theme = useContext(ThemeContext);
  const color = useSelector(themeSelect);
  const { logo_url } = useSelector(currentUserStateSelect) || {};
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  return (
    <Theming
      logo={logo_url}
      theme={theme}
      color={color}
      actions={actionsDispatch}
      {...props}
    />
  );
};

export default ThemingContainer;
