import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { createSelector } from "reselect";

import * as actions from "./actions";

import HomeView from "./component";

const userStateSelect = createSelector(
  (state) => state.app,
  (app) => app.currentUser
);

const HomeViewContainer = (props) => {
  const currentUser = useSelector(userStateSelect);
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  return (
    <HomeView currentUser={currentUser} actions={actionsDispatch} {...props} />
  );
};

export default HomeViewContainer;
