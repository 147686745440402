// import front-end config

// import {REHYDRATE} from 'redux-persist/lib/constants';

/**
 * ## InitialState
 *
 * The fields we're concerned with
 */
const initialState = {};

/**
 * ## Reducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function list(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
