// import front-end config
import { SIGNUP_S, FORGOT_PASSWORD_S } from "../actionsTypes";

import { LOGOUT_S } from "actionsTypes";

/**
 * ## InitialState
 *
 * The fields we're concerned with
 */
const initialState = {
  username: "",
};

/**
 * ## Reducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case FORGOT_PASSWORD_S:
    case SIGNUP_S:
      return { ...state, username: action.payload };

    case LOGOUT_S:
      return initialState;

    default:
      return state;
  }
}
