import { combineReducers } from "redux";

/*
 * Import reducers
 */
import details from "./details";
import create from "./create";
import list from "./list";

export default combineReducers({
  details,
  list,
  create,
});
