import Theming from "modules/Theming";

const routes = [
  {
    path: "/themes",
    component: Theming,
  },
  //   {
  //     path: '/attirbuts',
  //     component: Attributs,
  //   },
];

export default routes;
